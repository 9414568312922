import axios from "axios";
import Swal from "sweetalert2";
var headers = {
  "auth-token": JSON.parse(localStorage.getItem("token")),
  "Content-Type": "application/json",
};
export default {
  methods: {
    /* METODOS POST*/
    async obtenerDataApi(tipo = "get", url, parametros = null) {
      // let results;

      let config = {
        method: tipo,
        url: url,
        headers: headers,
        data: parametros,
      };
      const result = await axios(config).catch(function (error) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      });
      if (result.data.status == "200" && result.data.statusBol) {
        if (result.data.data) {
          return result.data.data;
        } else if (result.data.msg) {
          Swal.fire("CORRECTO", result.data.msg, "success");
          return true;
        }
      }
    },

    soloNumeros(e) {
      var key = window.event ? e.which : e.keyCode;

      if (key < 48 || key > 57) {
        e.preventDefault();
      }
    },
    soloTelefono(e) {
      var key = window.event ? e.which : e.keyCode;
      if (!((key >= 48 && key <= 57) || key == 40 || key == 41 || key == 43)) {
        e.preventDefault();
      }
    },

    currencyFormat(number) {
      let money = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "USD",
      }).format(number);
      return money;
    },

    calcularFactor(idshipment, metroscc, kg, containers = null) {
      let factor = 0;
      let valorPeso = 1;
      switch (idshipment) {
        case 1:
          valorPeso = metroscc * 166.66;
          factor = valorPeso > kg ? valorPeso : kg;
          break;

        case 2:
          containers.forEach((element) => {
            factor += element.cantidad;
          });
          break;
        case 3:
          valorPeso = kg / 1000;
          factor = valorPeso > metroscc ? valorPeso : metroscc;
          break;

        default:
          factor = 0;
          break;
      }
      return factor;
    },

    calcularFac(code = null, metroscc, kg, containers = null, amount) {
      let fac = 0;
      switch (code) {
        // 1	EMBARQUE
        case 1:
          fac = 1;
          break;
        // 2	M3
        case 2:
          fac = metroscc;
          break;
        // 3 TO/M3
        case 3:
          fac = kg / 1000 > metroscc ? kg / 1000 : metroscc;
          break;
        // 4	TONELADA
        case 4:
          fac = kg / 1000;
          break;
        // 5	% SOBRE EL VALOR CIF DE LA MERCANCIA
        case 5:
          fac = amount / 100;
          break;
        // 6	PESO CARGABLE
        case 6:
          fac = metroscc * 166.66 > kg ? metroscc * 166.66 : kg;
          break;
        // 7 "20 ST CONTENEDOR"
        case 7:
          fac = containers.filter((v) => v.id == 1 || v.idPrincipal == 1)[0]
            ? containers.filter((v) => v.id == 1 || v.idPrincipal == 1)[0]
                .cantidad
            : 0;

          break;
        // 8 "40 NOR CONTENEDOR "
        case 8:
          fac = containers.filter((v) => v.id == 4 || v.idPrincipal == 4)[0]
            ? containers.filter((v) => v.id == 4 || v.idPrincipal == 4)[0]
                .cantidad
            : 0;
          break;
        // 9  "40 HC CONTENEDOR "
        case 9:
          fac = containers.filter((v) => v.id == 3 || v.idPrincipal == 3)[0]
            ? containers.filter((v) => v.id == 3 || v.idPrincipal == 3)[0]
                .cantidad
            : 0;
          break;
        // 10 "40 ST CONTENEDOR "
        case 10:
          fac = containers.filter((v) => v.id == 2 || v.idPrincipal == 2)[0]
            ? containers.filter((v) => v.id == 2 || v.idPrincipal == 2)[0]
                .cantidad
            : 0;
          break;
        // 10  "% SEGURO SOBRE EL VALOR DE LA MERCANCIA",
        case 13:
          fac = 1;
          break;
        case 14:
          fac = 1;
          break;
        default:
          fac = 0;
          break;
      }
      return fac;
    },

    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },

    calcularValor(precio, flete, multiplicador, porcentaje = 0) {
      // SETEANDO DATOS
      let seguro = 0.015 * (parseFloat(precio) + parseFloat(flete));
      let cif = parseFloat(precio) + parseFloat(flete);
      let costo = 0;
      // CALCULAR LOS COSTOS
      switch (multiplicador) {
        case 5:
          if (porcentaje == 0) {
            costo = 0;
          } else if ((parseFloat(porcentaje) / 100) * parseFloat(cif) >= 60) {
            costo = (parseFloat(porcentaje) / 100) * parseFloat(cif);
          } else {
            costo = 60;
          }
          break;
        case 13:
          if (porcentaje == 0) {
            costo = 0;
          } else if ((parseFloat(porcentaje) / 100) * parseFloat(cif) >= 118) {
            costo = (parseFloat(porcentaje) / 100) * parseFloat(cif);
          } else {
            costo = 118;
          }
          break;
        case 14:
          if (porcentaje == 0) {
            costo = 0;
          } else if (
            (parseFloat(porcentaje) / 100) *
              (parseFloat(cif) + parseFloat(seguro)) >=
            118
          ) {
            costo =
              (parseFloat(porcentaje) / 100) *
              (parseFloat(cif) + parseFloat(seguro));
          } else {
            costo = 118;
          }
          break;
        case 15:
          if (cif <= 5000) {
            costo = 50;
          } else if (cif <= 10000) {
            costo = 80;
          } else {
            costo = cif * 0.0025 + 80;
          }
          break;

        default:
          costo = 0;
          break;
      }
      return costo;
    },
    async validarToken() {
      let val = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_URL_MAIN + "validToken",
        headers: {
          "auth-token": JSON.parse(localStorage.getItem("token"))
            ? JSON.parse(localStorage.getItem("token"))
            : "",
          "Content-Type": "application/json",
        },
      };

      await axios(config).catch(function (error) {
        if (error.response) {
          val = false;
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (
            error.response.status == "401" ||
            error.response.status == "500"
          ) {
            Swal.fire({
              icon: "error",
              title: "¡ERROR!",
              text: "Su sessión a caducado. Vuelva a iniciar Sesión.",
            }).then(() => {
              window.location.href = "/";
            });
          }
        } else if (error.request) {
          val = false;
          // The request was made but no response was received
          console.log(error.request);
        } else {
          val = false;
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
      return val;
    },
  },
};
